import { Tooltip, TooltipContent, TooltipTrigger } from '@upper/sapphire/ui'
import * as React from 'react'
import { FieldBaseProps, FormHelpText } from '..'
import { classNames } from './classnames'
import { FormErrorMessage } from './form-error-message'
import { FormLabel } from './form-label'
import type { InvalidProps } from './types'

export type SelectProps = {
  label?: React.ReactNode
  helpText?: React.ReactNode
  tooltip?: React.ReactNode
} & InvalidProps &
  FieldBaseProps &
  React.ComponentPropsWithoutRef<'select'>
type Ref = HTMLSelectElement

export const Select = React.forwardRef<Ref, SelectProps>(
  (
    {
      id,
      name,
      className,
      label,
      placeholder,
      children,
      isInvalid,
      invalidMessage,
      isHighlighted,
      required,
      helpText,
      tooltip,
      ...restProps
    },
    ref
  ) => {
    const selectMarkup = (
      <select
        ref={ref}
        id={id || name}
        name={name}
        className={classNames(
          isInvalid
            ? 'border-red-500 ring-1 ring-red-500 focus:border-blue-500 focus:ring-blue-500'
            : 'border-slate-200 focus:border-blue-500 focus:ring-blue-500',
          'block w-full overflow-hidden overflow-ellipsis rounded-md py-3 pl-3 pr-10 font-medium text-slate-600 disabled:opacity-50',
          isHighlighted ? 'text-blue-500' : 'text-slate-600',
          isHighlighted ? 'border-blue-500 bg-blue-50' : 'bg-white',
          className
        )}
        {...restProps}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {children}
      </select>
    )
    return (
      <div className={className}>
        {label && (
          <FormLabel htmlFor={id || name} required={required}>
            {label}
          </FormLabel>
        )}
        {tooltip ? (
          <Tooltip>
            <TooltipTrigger asChild autoFocus={false}>
              {selectMarkup}
            </TooltipTrigger>
            <TooltipContent autoFocus={false}>{tooltip}</TooltipContent>
          </Tooltip>
        ) : (
          selectMarkup
        )}
        {isInvalid && <FormErrorMessage>{invalidMessage}</FormErrorMessage>}
        {helpText && <FormHelpText>{helpText}</FormHelpText>}
      </div>
    )
  }
)
