/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useAuth } from '@upper/auth'
import { RoleKey } from '@upper/graphql/auth'
import {
  FellowDataDocument,
  UserFellowDataFragment
} from '@upper/graphql/fellow'
import { Button, Spinner } from '@upper/ui'
import * as React from 'react'
import { useQuery } from 'urql'

interface FellowDataContextInterface {
  fellowData?: UserFellowDataFragment | null
}

const FellowDataContext = React.createContext({} as FellowDataContextInterface)

function WithFellowData({ children }: { children: React.ReactNode }) {
  const { user, userHasRole, logout } = useAuth()
  const [{ data, fetching, error }] = useQuery({
    query: FellowDataDocument,
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      id: user?.id!,
    },
    pause: !user || !userHasRole(RoleKey.Fellow),
  })

  const value = React.useMemo(
    () => ({
      fellowData: data?.user?.fellowData,
    }),
    [data]
  )

  if (typeof window === 'undefined') return null

  if (user) {
    if (!userHasRole(RoleKey.Fellow)) {
      return (
        <div className="p-8">
          <p>You don&apos;t have enough permissions to view the fellow app</p>
          <div className="mt-10">
            <Button onClick={logout}>Logout</Button>
          </div>
        </div>
      )
    }

    if (fetching) {
      return (
        <div className="flex items-center justify-center h-screen">
          <Spinner />
        </div>
      )
    }

    if (error) {
      return <div>Error: {error.message}</div>
    }
  }

  return (
    <FellowDataContext.Provider value={value}>
      {children}
    </FellowDataContext.Provider>
  )
}

export function useFellowData() {
  const context = React.useContext(FellowDataContext)
  if (context === undefined) {
    throw new Error(`useFellowData must be used within a FellowDataProvider`)
  }
  return context
}

export default WithFellowData
